// extracted by mini-css-extract-plugin
export var block = "WhySyntax-module--block--45125";
export var contentItem = "WhySyntax-module--contentItem--77f6f";
export var contentItemText = "WhySyntax-module--contentItemText--328cc";
export var contentItemTitle = "WhySyntax-module--contentItemTitle--28e86";
export var contentItemsWrapper = "WhySyntax-module--contentItemsWrapper--63af2";
export var itemsWrapper = "WhySyntax-module--itemsWrapper--b922c";
export var leftLine = "WhySyntax-module--leftLine--9cc4a";
export var rightLine = "WhySyntax-module--rightLine--8b42f";
export var subtitle = "WhySyntax-module--subtitle--f7122";
export var title = "WhySyntax-module--title--557c2";