// extracted by mini-css-extract-plugin
export var bg = "LastPostBlock-module--bg--21a38";
export var block = "LastPostBlock-module--block--09f8e";
export var category = "LastPostBlock-module--category--2392b";
export var date = "LastPostBlock-module--date--25946";
export var info = "LastPostBlock-module--info--8e237";
export var postAuthor = "LastPostBlock-module--postAuthor--d2884";
export var postAuthorImg = "LastPostBlock-module--postAuthorImg--912b9";
export var postAuthorName = "LastPostBlock-module--postAuthorName--59875";
export var postItem = "LastPostBlock-module--postItem--8f6e8";
export var readingTime = "LastPostBlock-module--readingTime--a22b1";
export var title = "LastPostBlock-module--title--54831";