// extracted by mini-css-extract-plugin
export var block = "History-module--block--d7f91";
export var coloredDot = "History-module--coloredDot--9a172";
export var item = "History-module--item--9ed81";
export var itemLine = "History-module--itemLine--3cc83";
export var itemText = "History-module--itemText--99cbc";
export var itemTitle = "History-module--itemTitle--54f47";
export var itemYear = "History-module--itemYear--203b4";
export var itemsWrapper = "History-module--itemsWrapper--88f14";
export var lines = "History-module--lines--892c7";
export var space = "History-module--space--4e8ed";
export var title = "History-module--title--d1ee7";
export var whiteDot = "History-module--whiteDot--9dd4e";
export var wrapper = "History-module--wrapper--bdaf8";