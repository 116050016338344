// extracted by mini-css-extract-plugin
export var bg = "AcademyCourses-module--bg--a1e9c";
export var block = "AcademyCourses-module--block--739b7";
export var item = "AcademyCourses-module--item--8b374";
export var itemDescription = "AcademyCourses-module--itemDescription--607e3";
export var itemDuation = "AcademyCourses-module--itemDuation--78fd3";
export var itemImageWrapper = "AcademyCourses-module--itemImageWrapper--004e3";
export var itemTitle = "AcademyCourses-module--itemTitle--913cd";
export var lines = "AcademyCourses-module--lines--d4ef1";
export var title = "AcademyCourses-module--title--1bf88";
export var wrapper = "AcademyCourses-module--wrapper--456d7";