// extracted by mini-css-extract-plugin
export var block = "AlumniCourses-module--block--8ef50";
export var course = "AlumniCourses-module--course--6940f";
export var courseTitle = "AlumniCourses-module--courseTitle--db733";
export var description = "AlumniCourses-module--description--62060";
export var itemInfo = "AlumniCourses-module--itemInfo--be463";
export var label = "AlumniCourses-module--label--05187";
export var separator = "AlumniCourses-module--separator--cac0d";
export var title = "AlumniCourses-module--title--00b7d";
export var tuition = "AlumniCourses-module--tuition--d5407";
export var tuitions = "AlumniCourses-module--tuitions--1886a";
export var value = "AlumniCourses-module--value--c1821";